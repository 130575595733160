import React from "react";
import { Row, Col, Typography } from "antd";
import { Link } from "gatsby";
import Layout from "../../components/Layout/layout";
import SEO from "../../components/seo";
import CtaSecondary from "../../components/Buttons/secondary-cta";
import ogImage from "../../images/global_assets/og-image.png";
import LegalStyles from "../../components/Layout/LegalStyles";
import Heading from "../../components-v2/Base/HeadingBuilder";

const { Title } = Typography;

const MerchantTerms = () => {
  return (
    <Layout className="consumer-privacy">
      <SEO
        title="SpotOn | Privacy &amp; Legal"
        description="At SpotOn, we respect your privacy and are committed to being upfront and transparent. Please review our terms of services and privacy policies for more information."
        image={`https://spoton.com/${ogImage}`}
      />
      {/* <Affix offsetTop={0}> */}
      <div style={{ marginTop: 25 }}>
        <CtaSecondary target="/legal" ctaTitle="Legal" reverseIcon />
      </div>
      {/* </Affix> */}
      <main className="consumer-privacy__data">
        <Row>
          <Col lg={16} sm={24}>
            <div>
              <Title level={5}>EFFECTIVE AS OF DECEMBER 27, 2021.</Title>
              <Title level={1}>
                Merchant Terms and Conditions for Services Provided by SpotOn
                Transact, LLC
              </Title>
              <Heading level={2} injectionType={4} className="mt-0 specialty">
                For our User Terms of Service{" "}
                <Link to="/legal/user-terms">click here</Link>
              </Heading>
              <p>
                THE SERVICES PROVIDED BY SPOTON TRANSACT, LLC (“SPOTON”) ARE
                PROVIDED SUBJECT TO THE STANDARD TERMS AND CONDITIONS SET FORTH
                BELOW (THE “STANDARD TERMS”) AND THE ASSOCIATED MERCHANT
                APPLICATION AND PAYMENT TERMS (AS DEFINED IN SECTIONS 2 AND 3.
                RESPECTIVELY). THESE STANDARD TERMS AND ALL PAYMENT TERMS
                APPLICABLE TO CLIENT, AS WELL AS ALL TERMS AND POLICIES
                INCORPORATED INTO THESE STANDARD TERMS, INCLUDING THE DISPUTE
                RESOLUTION AND ARBITRATION AGREEMENT IN SECTION 11.2 BELOW,
                TOGETHER, CONSTITUTE THE CONTRACT BETWEEN SPOTON AND CLIENT (THE
                “CONTRACT”). THIS CONTRACT BECOMES BINDING WHEN CLIENT SIGNS UP
                FOR ANY SPOTON SERVICES OR WHEN CLIENT USES SPOTON SERVICES,
                INCLUDING THE SPOTON WEBSITE AND RELATED SERVICES.
              </p>
              <p>
                THE TERM “CLIENT” REFERS TO YOU, REGARDLESS OF WHETHER YOU ARE
                ACTING ON YOUR OWN BEHALF, OR THE COMPANY YOU REPRESENT,
                INCLUDING ALL LOCATIONS SUCH COMPANY REGISTERS FOR SPOTON
                SERVICES, WHETHER SUCH LOCATIONS ARE OWNED BY THAT COMPANY OR
                ARE FRANCHISEES (EACH, A “LOCATION”).
              </p>
              <p>
                CLIENT’S ACCEPTANCE OF THIS CONTRACT ALSO INCLUDES ACCEPTANCE OF
                SPOTON’S USER TERMS OF SERVICE (“USER TERMS”) AND PRIVACY
                POLICIES, BOTH OF WHICH ARE AVAILABLE AT
                <Link to="/">WWW.SPOTON.COM</Link> AND ARE INCORPORATED HEREIN
                BY REFERENCE.
              </p>
              <p>
                THIS CONTRACT CREATES BINDING LEGAL OBLIGATIONS. IF CLIENT DOES
                NOT AGREE TO BE BOUND BY THESE STANDARD TERMS AND/OR ANY PAYMENT
                TERMS, THE USER TERMS, AND THE PRIVACY POLICIES, YOU SHOULD NOT
                SIGN UP FOR OR USE SPOTON’S SERVICES. IF CLIENT DOES NOT ACCEPT
                THIS CONTRACT, CLIENT WILL NOT BE ABLE TO USE SPOTON’S SERVICES.
              </p>
              <p>
                SPOTON RESERVES THE RIGHT, IN ITS SOLE DISCRETION, TO MODIFY OR
                REPLACE ANY PART OF THESE STANDARD TERMS, ITS PRIVACY POLICY OR
                THE USER TERMS AT ANY TIME, AND SUCH MODIFIED TERMS WILL BE
                EFFECTIVE UPON THE DATE OF SUCH CHANGE. SPOTON WILL NOTIFY
                CLIENT OF SUCH CHANGES BY POSTING THEM ON ITS WEBSITE AND
                POSSIBLY BY OTHER MEANS. YOUR CONTINUED USE AND ACCESS TO THE
                SERVICES FOLLOWING ANY POSTING OF ANY CHANGES TO THESE STANDARD
                TERMS CONSTITUTES YOUR ACCEPTANCE OF THOSE CHANGES.
              </p>
              <p>
                SpotOn offers a variety of services and tools for merchants,
                service providers, and restaurants, including tools for
                electronic marketing, appointment management, loyalty programs,
                review tracking, payment processing, payroll management,
                ordering applications, self-service customer check-in services,
                website services, and integrated point of sale (“POS”) systems,
                and expects to offer additional services in the future. SpotOn
                may also provide services to customers of Client, referred to as
                “Users” (e.g., subscribing to or checking balances in loyalty
                programs, using online ordering or reservation services through
                a SpotOn website or mobile app). All services offered by SpotOn,
                currently or in the future, whether offered to Client or to
                Users who are customers of Client, are referred to as the
                “Services,” and are subject to this Contract. Certain Services
                are described briefly below, and all Services are described on
                SpotOn’s website,<Link to="/">www.spoton.com</Link>, and/or in
                other marketing or explanatory materials, the Merchant
                Application (as defined in Section 2), or other materials
                provided by SpotOn (collectively, the “Service Descriptions”).
              </p>
              <p>
                Client wishes to use some or all of the Services for Client’s
                business purposes, and SpotOn agrees to provide Client with the
                Services for which Client subscribes, pursuant to this Contract.
              </p>
              <Title id="info" level={2}>
                1. Description of Services
              </Title>
              <p>Services generally include the following:</p>
              <Title level={3}>1.1 Marketing and Payment Services.</Title>
              <p>
                The “Marketing and Payment Services,” which include options for
                a loyalty program, review tracking, and payment processing,
                allow Client to send a variety of messages, special offers,
                coupons, appointment reminders, and other information
                (collectively, “Notes”) to Users (as defined in Section 6.4)
                through various social media platforms, which may include email,
                mobile communications (e.g., SMS text messaging or other mobile
                notifications), Facebook, Twitter, and possibly other social
                media platforms. All of such communication platforms, together
                with any websites owned or controlled by Client, are referred to
                as “Program Sites.” Any third-party sites to which Client adds
                content via a SpotOn proprietary dashboard (“Dashboard”) or via
                any other Service or tool made available by SpotOn, are also
                included in the definition of Program Sites. “Marketing and
                Payment Services” may include Hardware (as defined in Section
                1.5), software, and services that allow Client to register Users
                and process requests and payments for Users.
              </p>
              <Title level={3}>1.2. SpotOn Website Services.</Title>
              <p>
                “SpotOn Website Services” allow Client to create, update,
                publish, and operate its own website.
              </p>
              <Title level={3}>1.3. POS Services.</Title>
              <p>
                The “POS Services” include an integrated system that combines
                Hardware, software, and services that allow Client to process
                orders and payments from fixed or mobile devices and integrate
                the resulting data with certain of SpotOn’s other Services. The
                POS Services for restaurants (“SpotOn Restaurant POS”) include
                features designed specifically for restaurants and the sale of
                Hardware to the Client at a discount.
              </p>
              <Title level={3}>1.4. Outsourced Services.</Title>
              <p>
                SpotOn also provides certain Services, such as for payroll,
                through third parties.
              </p>
              <Title level={3}>1.5. Hardware.</Title>
              <p>
                Client may purchase or rent devices, equipment, hardware, or
                other materials (collectively, “Hardware”) provided by SpotOn
                for use with certain Services, as described in the applicable
                Service Descriptions. If such Hardware is rented from SpotOn,
                Client understands that SpotOn will continue to be the owner of
                such Hardware, and that Client has the obligation to return the
                Hardware to SpotOn within 15 days of the termination of this
                Contract. Client will not remove, alter, efface, cover, or
                otherwise modify any markings, stickers, or other indicia
                applied to any Hardware loaned or rented by SpotOn. Client will
                use such Hardware exclusively in conjunction with the applicable
                Service(s) and for no other purpose. Client will use such
                Hardware in accordance with normal usage, and will not alter, or
                seek to alter, any such Hardware. Client will be responsible for
                the security of such Hardware and for maintaining such Hardware
                in good and operable condition, subject to ordinary wear and
                tear.
              </p>
              <Title level={2}>2. Merchant Application</Title>
              <p>
                In order to access SpotOn’s Services, Client shall execute and
                return to SpotOn a merchant processing application or other
                merchant application and agreement provided by SpotOn
                (hereinafter, each, a “Merchant Application”), which lists the
                particular Services Client desires to use and certain payment
                terms. The Merchant Application also incorporates the Terms and
                Conditions of SpotOn’s payment processor, which are available
                under the Payments tab at www.spoton.com/privacy. The terms of
                the Merchant Application agreed to by Client are incorporated
                into these Standard Terms. In the event of any inconsistency
                between the terms of a Merchant Application and these Standard
                Terms, these Standard Terms shall apply unless the Merchant
                Application specifically states that they are being amended or
                superseded.
              </p>
              <Title level={2}>3. Payments</Title>
              <p>
                Upon acceptance of the Merchant Application by SpotOn and/or its
                bank, SpotOn will be authorized to charge Client’s credit card
                or debit Client’s bank account for all “Fees” (as described in
                this Section 3) payable under this Contract. The Fees for each
                Service are described in the applicable Service Description, a
                Merchant Application, or as otherwise agreed to by the parties
                (collectively, the “Payment Terms”). The following terms apply
                to all Services, and are in addition to the terms included in
                the applicable Merchant Application.
              </p>
              <Title level={3}>3.1 Subscriptions</Title>
              <p>
                All Services are provided on a subscription basis, in some cases
                after a free trial period, all as described in the applicable
                Service Descriptions, Merchant Application, or as otherwise
                agreed to by the parties. Subscriptions will be on a monthly
                basis or otherwise as agreed by the parties. All subscription
                Fees will be paid in advance of the subscription period, or as
                otherwise agreed to in writing by the parties, via the payment
                method authorized by Client.
              </p>
              <Title level={3}>3.2. Activity Fees.</Title>
              <p>
                Activity Fees for any transactions (e.g., SMS text messaging or
                other processing fees) will be payable in arrears via the
                payment method authorized by Client.
              </p>
              <Title level={3}>3.3. Hardware Fees.</Title>
              <p>
                If applicable, purchase or rental Fees for Hardware provided by
                SpotOn will be payable as set forth in the applicable Service
                Descriptions, Merchant Application, or as otherwise agreed to by
                the parties. In the event Client does not return rented or
                loaned Hardware within 30 days of the termination of this
                Contract, SpotOn may charge Client a replacement fee, the amount
                of which shall be as set forth in the applicable Merchant
                Application or otherwise included in the Service Descriptions,
                or, if not otherwise specified, as determined by SpotOn. In the
                event of an inconsistency between the replacement fee and the
                terms in the Merchant Application or those in other portions of
                the Service Descriptions, the terms of the Merchant Application
                will control. The replacement fee will be debited to Client
                according to the payment method authorized by Client. Upon
                receipt of such payment, title to such Hardware will vest in
                Client. Clients using SpotOn Restaurant POS that terminate this
                Contract within one year of its Effective Date may be charged
                the difference between the list price for the Hardware purchased
                from SpotOn and the discounted price paid by the Client, and
                that price adjustment may be debited to Client according to the
                payment method authorized by Client.
              </p>
              <Title level={3}>3.4. Fee Changes.</Title>
              <p>
                SpotOn may change any Fees that it charges for any Services or
                Hardware provided by SpotOn by giving Client notice of such
                change (the “Fee Change”) at least 30 days before the change
                becomes effective. If Client does not agree to any such Fee
                Change, Client may terminate this Contract (and Client’s use of
                the Services) by notifying SpotOn of termination prior to the
                effective date of the Fee Change. This Contract and Client’s use
                of the Services shall thereupon be terminated as of the
                effective date of the Fee Change. Client’s failure to terminate
                this Contract prior to the effective date of the Fee Change
                shall constitute Client’s acceptance of the Fee Change.
              </p>
              <Title level={3}>3.5. Notices of Charges.</Title>
              <p>
                The amount to be debited to Client’s account or charged to
                Client’s credit card for the Services shall be adjusted pursuant
                to any Fee Change accepted (or deemed accepted) by Client,
                starting on the effective date of the Fee Change. Client may
                view its amounts payable and past charges by SpotOn on the
                Dashboard.
              </p>
              <Title level={3}>3.6. Obligation of Client for Fees.</Title>
              <p>
                Client agrees not to terminate its authorization for debits to
                Client’s account or charges to Client’s credit card until all
                Fees and charges payable under this Contract have been paid in
                full. All Fees and charges payable hereunder shall remain
                enforceable obligations of Client regardless of whether: (i)
                Client terminates its debit or charge card authorization prior
                to collection in full by SpotOn, (ii) an authorized payment
                fails or is rejected, or (iii) Client challenges or seeks to
                reverse any charge authorized under this Contract.
              </p>
              <Title level={3}>3.7. Debit Authorization.</Title>
              <p>
                By providing its credit card or checking account information to
                SpotOn, Client agrees that SpotOn is authorized to charge
                Client’s credit card or checking account for all Fees payable
                under this Contract. Service(s) may be interrupted on accounts
                that reach 10 days past due. Accounts that are not collectable
                by SpotOn may be turned over to an outside collection agency for
                collection.
              </p>
              <Title level={3}>3.8. Taxes.</Title>
              <p>
                Client is responsible for paying all taxes applicable to or
                resulting from the Services and transactions contemplated by
                this Contract. If SpotOn is required to collect or pay taxes on
                any Services or Hardware provided to Client by SpotOn that were
                not billed to Client, SpotOn may charge Client for such taxes
                via the credit card or checking account authorized by Client for
                other charges. Client is also responsible for calculating and
                paying all taxes associated with its sale or delivery of
                products or services to its customers, as described in Section
                6.14.
              </p>
              <Title level={2}>Proprietary Rights.</Title>
              <Title level={3}>4.1. Client’s Proprietary Rights.</Title>
              <p>
                SpotOn may use certain trademarks and copyrighted material of
                Client on SpotOn apps or SpotOn websites and on other sites and
                apps applicable to the type of Services selected by Client or to
                provide Services to Users who are customers of Client. Client
                consents to all such usage by SpotOn. Client retains all rights
                in and to its trademarks and copyrights.
              </p>
              <Title level={3}>4.2. SpotOn’s Proprietary Rights.</Title>
              <p>
                SpotOn has developed or acquired proprietary software and
                systems through which it delivers the Services, including the
                Dashboard and SpotOn’s internal tools (collectively, the “SpotOn
                Software”). SpotOn grants Client a license to access the SpotOn
                Software applicable to the Services selected by Client via the
                internet during the Term of this Contract, but only for the
                internal use of Client and subject to the other terms and
                conditions of this Contract. SpotOn has developed trademarks,
                copyrighted material, and other intellectual property that it
                may use in conjunction with the Services, including on displays
                utilized by the Client and/or Users in conjunction with the
                Services, on websites, and apps related to the Services, and/or
                with Hardware; or that SpotOn may otherwise use on behalf of
                Client or in conjunction with making the Services available for
                use by Client or Users. All (i) SpotOn Software, (ii) any SpotOn
                Dashboard, (iii) any SpotOn website or mobile app, and (iv) all
                other software, dashboards, databases, tools, websites, mobile
                apps, portals, technology and systems used or offered by SpotOn
                in conjunction with the Services are collectively referred to as
                “SpotOn Technology.” All SpotOn Technology and all trademarks
                and other materials used by SpotOn in the Services (other than
                those owned by Client or third parties), are owned by SpotOn or
                its licensors.
              </p>
              <Title level={3}>4.3. Third Party Proprietary Rights:</Title>
              <p>
                The Services may involve interaction with proprietary and/or
                branded websites, apps, or other technology of third parties,
                each of which retains all rights in and to its trademarks,
                tradenames, copyrights, and SpotOn Technology (collectively,
                “Proprietary Materials”). Such third parties may include, for
                example, Facebook, Twitter, Google, Yelp, Foursquare, OpenTable,
                TripAdvisor, and other third parties now or in the future.
                Client has no right to use any Proprietary Materials of any
                third parties.
              </p>
              <Title level={3}>4.4 Feedback.</Title>
              <p>
                Any information provided by Client to SpotOn, including
                questions, comments, suggestions, ideas, feedback, or other
                material (collectively, “Feedback”) is not confidential. Client
                hereby grants SpotOn a worldwide, perpetual, irrevocable,
                royalty-free license to reproduce, display, perform, distribute,
                publish, modify, edit, incorporate into SpotOn Technology, or
                otherwise use such Feedback as SpotOn deems appropriate for any
                and all commercial and/or non-commercial purposes, in SpotOn’s
                sole discretion.
              </p>
              <Title level={2}>
                5. Representations, Warranties and Covenants of SpotOn.
              </Title>
              <p>
                SpotOn represents and warrants to Client that SpotOn will
                provide the Services in compliance with (i) applicable laws and
                regulations and (ii) consistent with SpotOn’s Services
                Descriptions.
              </p>
              <Title level={2}>
                6. Representations, Warranties and Covenants of Client.
              </Title>
              <p>
                Client represents, warrants and covenants to SpotOn as follows:
              </p>
              <Title level={3}>6.1. Noninfringement.</Title>
              <p>
                Client has all rights to all materials delivered by Client for
                use (or approved by Client for use) in conjunction with the
                Services, including for use on Program Sites, SpotOn apps, the
                Dashboard, and/or SpotOn websites, and to all materials used by
                Client, or provided by Client for SpotOn to use, in conjunction
                with the Services, without infringing the rights of SpotOn or
                any third party.
              </p>
              <Title level={3}>6.2. Compliance with Law and Policy.</Title>
              <p>
                Client will use the Services only for Client’s business purposes
                and will comply with all applicable laws and regulations, the
                terms of the published policies and programs of both SpotOn and
                Client. The foregoing includes all laws and regulations relating
                to privacy and security (including all laws and regulations
                relating to personal health information, financial information
                or other sensitive information), the CAN-SPAM Act of 2003, 15
                U.S.C. § 103, et seq. (the “CAN-SPAM Act”), the Telephone
                Consumer Protection Act, 47 U.S.C. § 227, et seq. (“TCPA”) and
                all other laws and regulations relating to marketing to entities
                and / or individuals.
              </p>
              <Title level={3}>6.3. Privacy and User Terms.</Title>
              <p>
                Client (including each Location) will comply with the Consumer
                Privacy Policy and User Terms, both of which are incorporated
                herein by reference, the Payment Terms applicable to the
                Services selected by Client, and to all other policies
                established by SpotOn. Specifically, but without limiting the
                foregoing, Client will not use any data relating to Users or
                take any other action that the Consumer Privacy Policy or User
                Terms state may be done only with the permission of SpotOn until
                and unless Client has received written permission from SpotOn to
                do so, and Client will comply with any terms required by SpotOn
                in conjunction with granting such permission. Client will not
                use any data relating to Users or take any other action that is
                inconsistent with the User Terms, the Consumer Privacy Policy,
                any other SpotOn policy, or any applicable Service Description.
                In addition, Client understands that it is solely responsible
                for posting and disseminating to Users its own terms of use and
                a privacy policy on its websites or apps. Client hereby
                represents and warrants that its terms of use and privacy policy
                are consistent with this Contract and do not violate any
                applicable law, including CAN-SPAM and the TCPA.
              </p>
              <Title level={3}>6.4. User Interactions.</Title>
              <p>
                Client shall be solely responsible for the creation and
                dissemination of all communications with its customers,
                prospective customers, and others with whom it communicates
                through the use of the Services and/or SpotOn Technology
                (collectively, “Users”). SpotOn does not create, send,
                disseminate, initiate, make, or take any of the steps necessary
                to send any communications to Users. SpotOn shall not be
                directly liable for any communications sent to Users. Client
                will communicate with Users only in compliance with all
                applicable laws, rules, and regulations, including CAN-SPAM and
                the TCPA, and all other applicable laws.
              </p>
              <ol>
                <li>
                  <Title level={4}>6.4.1.</Title>
                  <p>
                    The TCPA, CAN-SPAM, and other laws may require Client to
                    obtain consents from Users before Client sends any
                    communications to Users. Client shall (i) bear the sole
                    responsibility for obtaining any legally required consents
                    from Users prior to sending any communications to Users,
                    (ii) have the sole responsibility for storing and
                    maintaining records of any legally required consents, (iii)
                    maintain and implement a system that allows Users to opt-out
                    of future communications from Client or to alter or revoke
                    any consents related to communications from Client, and (iv)
                    not send communications to Users beyond the frequency
                    represented to such Users in any disclosures or terms
                    provided by Client.
                  </p>
                </li>
                <li>
                  <Title level={4}>6.4.2.</Title>
                  <p>
                    In addition to, and not in limitation of the foregoing,
                    Client shall (i) comply with all laws and regulations,
                    including CAN-SPAM and the TCPA, that require consent for
                    communications to Users, (ii) be solely responsible for
                    compliance with all applicable laws, regulations, and rules,
                    including CAN-SPAM and the TCPA, and including all terms of
                    this Contract, and (iii) have sole legal liability for any
                    act, omission or violation of any of the foregoing by Client
                    or any representative or contractor of Client that may occur
                    in connection with Client’s use of the Services and/or
                    SpotOn Technology.
                  </p>
                </li>
                <li>
                  <Title level={4}>6.4.3. </Title>
                  <p>
                    Client agrees (i) that Client shall have sole responsibility
                    for making and sending all messages to Users, and for the
                    content and frequency of all such messages, and (ii) that
                    SpotOn merely provides Client with the related Services
                    and/or SpotOn Technology. Client is solely responsible for
                    receiving consent from Users to submit to SpotOn any
                    information relating to the User as required in order to
                    include such User in the applicable Services, and Client
                    represents, warrants and covenants that providing such
                    information about the User does not and will not violate any
                    obligation Client has to such User, including pursuant to
                    the Consumer Privacy Policy or the User Terms, Client’s
                    privacy policy, CAN-SPAM, the TCPA, or any other applicable
                    law, regulation, or policy. Client is solely responsible for
                    designing, describing, administering, and fulfilling the
                    terms set forth in any descriptions of its products or
                    services, or any promotions, deals, or programs offered by
                    Client to Users, as communicated by Client using any of the
                    Services. SpotOn is not responsible for any complaints or
                    actions by Users resulting from Client’s failure to fulfill
                    its obligations as described in the foregoing sentence.
                  </p>
                </li>
              </ol>
              <Title level={3}>6.5. User Communications.</Title>
              <p>
                In addition to compliance with Section 6.4, Client will ensure
                that all interactions with Users are in compliance with (i) the
                terms of this Contract, and (ii) the terms of any offers,
                commitments, or representations Client has made to Users. In
                addition, Client represents, warrants and covenants that no
                communication with Users or published material made available to
                Users through the use of the Services (including Notes and
                Client websites) will contain: (a) any sexually explicit
                materials; (b) any viruses, Trojan horses, worms, or other
                harmful code; (c) disclosure of any personally identifiable
                information (without first obtaining the prior express written
                consent from the data subject); (d) any content commonly
                associated with unsolicited commercial messages (e.g., spam);
                (e) any libelous, scandalous, defamatory, disparaging, vulgar,
                profane, threatening, hateful, or harassing message, (f) any
                incitement to illegal activity, harassment, or violence; (g) any
                images, audio, video, or other content created by a third party,
                without obtaining the prior written consent from the content
                owner; or (h) any other types of content that may be considered
                objectionable, illicit or illegal (all of the foregoing,
                collectively, “Offensive Content”).
              </p>
              <Title level={3}>6.6. No Agency Relationship.</Title>
              <p>
                No agency relationship between SpotOn and Client is created by
                this Contract or by Client’s use of the Services and/or SpotOn
                Technology. Neither Client nor SpotOn shall be an agent of the
                other party for any purpose. Specifically, and without limiting
                the foregoing, Client does not have (i) express actual authority
                to act on behalf of SpotOn, (ii) implied actual authority to act
                on behalf of SpotOn, or (iii) apparent authority to act on
                behalf of SpotOn, and Client has no authority to create
                sub-agency relationships with any other individuals or entities.
                Client agrees that SpotOn shall not be liable for Client’s
                conduct under the doctrine of respondeat superior or any similar
                common law doctrine or statutory provision. Client’s officers,
                directors, employees, staff, or agents are not de facto SpotOn
                employees. SpotOn does not ratify any conduct that violates the
                laws or regulations of any jurisdiction or this Contract.
              </p>
              <Title level={3}>6.7. Relationships with Third Parties.</Title>
              <p>
                Client shall not retain any third parties for any purpose unless
                the third parties agree to comply with the terms of this
                Contract and with all prevailing laws and regulations, including
                CAN-SPAM and the TCPA and any associated regulations, and
                contractual terms regarding CAN-SPAM and TCPA compliance that
                are at least as stringent as the requirements set forth this
                Contract. Client shall bear all legal responsibility if a third
                party it retains for any purpose engages in conduct that
                violates the laws or regulations of any jurisdiction, including
                CAN-SPAM and the TCPA. Client shall not allow any third parties
                to use the Software and / or SpotOn Technology to create, send,
                disseminate, initiate, make or take any of the steps necessary
                to send any communications on its behalf. If Client purchases
                leads, phone numbers, or other contact information from a third
                party, Client shall bear all legal responsibility for ensuring
                that the third party obtained all consents required under the
                TCPA, CAN-SPAM, or other applicable laws or regulations.
              </p>
              <Title level={3}>6.8. Use of Program Site.</Title>
              <p>
                Client will not (i) use any Program Site in a manner that is
                contrary to the terms governing such Program Site, including the
                privacy policy of such Program Site, or (ii) take any action
                that might interfere with or compromise SpotOn’s relationship
                with any Program Site. Client will not make any representations
                or warranties on its website, on any third-party website, in any
                Program Site or other social media, in Client’s marketing
                materials or otherwise, regarding the Services that might harm
                SpotOn’s reputation or its relationship with any third parties,
                including any Program Site.
              </p>
              <Title level={3}>
                6.9. No Infringing or Inappropriate Content.
              </Title>
              <p>
                Client will not disseminate, whether on its premises, on any
                Program Site, via any communication to Users (including via
                Notes), in the Chat Facilities (as defined in Section 12.8),
                through Client’s website published through the SpotOn Website
                Services or otherwise, any content that Client does not have the
                right to use (“Infringing Content”), or any content that in the
                sole judgment of SpotOn may be Offensive Content (Infringing
                Content and Offensive Content, collectively, “Inappropriate
                Content”).
              </p>
              <Title level={3}>6.10. No Interference.</Title>
              <p>
                Client will not upload, post, e-mail or otherwise transmit any
                material that contains a virus or other mechanism designed to
                interrupt, destroy or limit the functionality of any SpotOn
                Technology, any of the Services, or any software or system of a
                third party. Client will not interfere with the provision or use
                of the Services by SpotOn, by any other client of SpotOn, or by
                any User, nor will Client interfere with any other SpotOn
                Technology or Services offered by SpotOn. Except as authorized
                by SpotOn, Client will not seek to download, obtain the code for
                or in any other way seek to access the SpotOn Software or any
                other SpotOn Technology.
              </p>
              <Title level={3}>6.11.Use of Data by Client.</Title>
              <p>
                Client will use data relating to Users obtained through the use
                of the Services solely to market Client’s goods and services to
                Users through the Services during the Term. Unless otherwise
                agreed to by the parties, Client will not download or make
                copies (whether in hard copy or electronic) of User information
                collected by SpotOn or by Client through SpotOn Technology or
                the Services except to the extent such downloading and/or use
                (i) is during the Term of this Contract, (ii) is solely for
                Client’s internal business purposes, and (iii) is in accordance
                with SpotOn’s Privacy Policies and User Terms, and in accordance
                with applicable laws and regulations. If after the Term of this
                Contract Client contacts any person whose information was
                collected other than through SpotOn or SpotOn Technology,
                Sections 6.4 and 6.7 of this Contract shall continue to apply to
                all such contacts. Client will use information relating to Users
                only as permitted herein. Client will not provide any
                information obtained through the use of the Services, including
                any information that would personally identify, or facilitate
                personal contact with, any User (e.g., first and/or last names,
                email, telephone number, zip code, birthdate, gender, credit
                card or other financial information, or other identifying
                information) to any third party other than (a) to an acquirer in
                the event of a sale or merger of Client’s business, provided
                that the acquirer agrees to maintain and use such data regarding
                Users in accordance with this Contract, including the Privacy
                Policies and User Terms, (b) as necessary to fulfill an order
                for a User, (c) to service providers of Client who need to know
                such information to provide services to Client consistent with
                this Contract and are obligated to keep such information
                confidential, (d) if required by law or subpoena, in response to
                an inquiry from law enforcement authorities or regulators, or
                (e) if Client believes the release of such information is
                necessary to address or prevent illegal or harmful activity.
                Client will not obtain or seek to obtain access to any nonpublic
                information of SpotOn, any other SpotOn client or other third
                party, or User information maintained on behalf of another
                SpotOn client.
              </p>
              <Title level={3}>6.12. Multiple Locations.</Title>
              <p>
                If Client registers more than one Location to use the Services,
                whether or not such Location is owned by Client, or is a
                franchisee or other licensee of Client, Client represents and
                warrants that it has the authority to commit such Location to
                the terms of this Contract. Client will be responsible for
                either fulfilling the responsibilities or for arranging such
                Location to fulfill the responsibilities set forth in this
                Contract, including the payment of Fees (as described in Section
                3).
              </p>
              <Title level={3}>
                6.13. Accuracy, Currency, and Retention of Data.
              </Title>
              <p>
                Client is responsible for the accuracy of the information Client
                (including each Location) provides SpotOn relating to Users and
                for updating such information as may be necessary to keep it
                current. Client will not rely on the information it transmits to
                SpotOn as an archive or backup, and will be responsible for
                retaining copies of all information Client sends SpotOn for
                which Client may have a need or wish to use. SpotOn makes no
                representations or warranties about its ability to return
                information relating to Users or the accuracy or completeness of
                any such information if provided to Client, upon Client’s
                reasonable request and SpotOn’s subsequent agreement to provide
                such data.
              </p>
              <Title level={3}>
                6.14. Client Taxes and Other Payment Obligations.
              </Title>
              <p>
                Client will be responsible for recording, calculating, paying,
                and reporting (i) all federal, state and local taxes and fees
                due for Client’s services and products provided to Users, (ii)
                all compensation and any other payments due to Client’s
                employees and other personnel and providers, including hours
                worked or other services or materials provided, overtime, tips
                and any other payment obligations, (iii) all taxes and fees
                related to payroll or other payments paid or payable to
                employees or other third parties, and (iv) all taxes and fees
                resulting from Client’s use of the Services (except for taxes on
                SpotOn’s income), whether levied initially on Client or on
                SpotOn. To the extent SpotOn or SpotOn Technology provides
                information to Client with respect to any such calculation, tax,
                or other payment or reporting obligation, such information is
                provided merely as a convenience to Client, shall not be relied
                upon by Client, and does not relieve Client of its obligations
                described above.
              </p>
              <Title level={3}>6.15. Payment Processing.</Title>
              <p>
                When Users use a credit or debit card or any other non-cash
                payment means through SpotOn Technology (including a SpotOn POS
                system, SpotOn Website Services, or a SpotOn website or mobile
                app), Client will not seek to discover or record the credit or
                debit card number or other payment details. When Users interact
                directly with Client without the use of any SpotOn Technology,
                Client will record such payment details only with the express
                written consent of the User. Client will use a User’s payment
                information only consistent with the scope of such User’s
                authorization (e.g., to process payments) and will not disclose
                any payment information of Users to a third party without the
                User’s written consent. Notwithstanding the foregoing, Client
                may disclose such payment information to (i) third parties
                necessary to effect a transaction, provided that such third
                parties have an obligation to use the payment information only
                for purposes of carrying out the transaction or for other
                activities that have been authorized by the applicable User, and
                (ii) when required under applicable law.
              </p>
              <p>
                SpotOn is responsible for the Services that could impact the
                security of User cardholder data. However, Client acknowledges
                and agrees the Client shall at all times be responsible for the
                security of User cardholder data the Client possesses, stores,
                processes, or transmits on behalf of User. Further, since SpotOn
                utilizes Third Party Providers for processing and transmitting
                payments, and storing User cardholder data, SpotOn does not
                transmit or store the User cardholder data at any time during
                the process. Client acknowledges and agrees, and shall inform
                and obtain User acknowledgement and agreement: (a) Third Party
                Providers shall at all times be solely responsible for the
                security of User cardholder data that Third Party Providers
                possess, store, process, or transmit on behalf of Client and
                User, (b) Client and User must direct claims relating to the
                security of User cardholder data directly to Third Party
                Providers, and (c) SpotOn does not warrant, indemnify, or
                accepts liability for such claims.
              </p>
              <Title level={2}>
                7. Disclaimers; Limitations of Liability; Release and Waiver
              </Title>
              <Title level={3}>7.1. Third Party Services</Title>
              <p>
                Client understands and agrees that (i) certain Services offered
                by SpotOn are delivered by third parties (“Third Party
                Providers”) or through the technology of third parties (“Third
                Party Technology”), (ii) SpotOn offers Services through the
                internet and through Third Party Providers and other third
                parties, including internet service providers, gateways, domain
                name registrars, Program Sites, and other social networking or
                third party sites or services (collectively, “Third Party
                Services”), and (iii) the Hardware provided hereunder that was
                sold, rented, or loaned to Client by SpotOn that is manufactured
                by a third party (“Third Party Devices”). Client agrees that
                SpotOn is not responsible for the performance of the internet,
                any Third Party Providers, Third Party Technology, Third Party
                Services or Third Party Devices, or for the reliability,
                security, availability, compliance with law or any other aspect
                of the internet, or any Third Party Providers, Third Party
                Technology, Third Party Services or Third Party Devices. CLIENT
                HEREBY RELEASES SPOTON FROM ANY DAMAGES CLIENT OR USERS MAY
                INCUR AS A RESULT OF USE OF (A) THE INTERNET; (B) ANY SPOTON
                TECHNOLOGY, (C) ANY SERVICES PROVIDED BY SPOTON; OR (D) ANY
                THIRD PARTY PROVIDERS, THIRD PARTY TECHNOLOGY, THIRD PARTY
                SERVICES, OR THIRD PARTY DEVICES. CLIENT AGREES NOT TO ASSERT
                ANY CLAIMS AGAINST SPOTON, ITS OFFICERS, DIRECTORS, EMPLOYEES,
                AGENTS, OR REPRESENTATIVES (COLLECTIVELY, “ASSOCIATED PERSONS”)
                ARISING IN ANY WAY FROM USE OF THE INTERNET, ANY TECHNOLOGY, OR
                ANY THIRD PARTY PROVIDERS, THIRD PARTY TECHNOLOGY, THIRD PARTY
                SERVICES, OR THIRD PARTY DEVICES. In connection with the
                foregoing release, Client hereby waives California Civil Code
                Section 1542, and any similar provision in any other
                jurisdiction. California Civil Code Section 1542 provides: “A
                general release does not extend to claims which the creditor
                does not know or suspect to exist in his favor at the time of
                executing the release, which, if known by him must have
                materially affected his settlement with the debtor.”
              </p>
              <Title level={3}>
                7.2. No Assurance of Availability of Services.
              </Title>
              <p>
                Client understands and agrees that the Services, Third Party
                Services, Third Party Technology and Third Party Devices may be
                unavailable from time to time for maintenance or other reasons,
                and that SpotOn is not responsible for any error, omission or
                interruption in the Services or in the performance of the Third
                Party Services, Third Party Technology or Third Party Devices,
                including any defect or delay in operation or transmission;
                communications failure; deletion, theft, destruction, or
                unauthorized access to or alteration of any content, (i) that
                SpotOn sends through the Services, (ii) that Client collects,
                processes, stores, or sends through use of the Services, Third
                Party Services, Third Party Technology or Third Party Devices,
                or (iii) that Client attempts to so collect, process, store, or
                send; or for any technical malfunction or other difficulty
                Client may experience in the use of the Services, Third Party
                Services, Third Party Technology or Third Party Devices.
              </p>
              <Title level={3}>
                7.3. Disclaimer of Representations and Warranties.
              </Title>
              <p>
                The representations, warranties, and covenants set forth in
                Section 5 are the only representations, warranties or covenants
                made by SpotOn. SPOTON HEREBY EXPRESSLY DISCLAIMS ALL OTHER
                REPRESENTATIONS, WARRANTIES AND COVENANTS, INCLUDING THE IMPLIED
                WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE
                AND NONINFRINGEMENT. THE SERVICES, THIRD PARTY SERVICES, THIRD
                PARTY TECHNOLOGY, AND THIRD PARTY DEVICES ARE PROVIDED ON AN “AS
                IS” AND “AS AVAILABLE” BASIS, WITH ALL FAULTS. THE FOREGOING
                DISCLAIMER INCLUDES (i) A DISCLAIMER OF ANY REPRESENTATION,
                WARRANTY OR COVENANT THAT THE SERVICES, SPOTON TECHNOLOGY, THIRD
                PARTY SERVICES, THIRD PARTY TECHNOLOGY, AND/OR THIRD PARTY
                DEVICES, WHETHER MADE AVAILABLE OR RECOMMENDED BY SPOTON WILL BE
                UNINTERRUPTED, RELIABLE, SECURE OR ERROR FREE; (ii) A DISCLAIMER
                THAT THE SERVICES, SPOTON TECHNOLOGY, ANY THIRD PARTY PROVIDERS,
                THIRD PARTY SERVICES, THIRD PARTY TECHNOLOGY, OR THIRD PARTY
                DEVICES ARE FREE OF VIRUSES OR HARMFUL COMPONENTS; AND (iii) A
                DISCLAIMER THAT THE SERVICES, ANY SPOTON TECHNOLOGY, ANY THIRD
                PARTY PROVIDERS, THIRD PARTY TECHNOLOGY, THIRD PARTY SERVICES,
                OR THIRD PARTY DEVICES WILL MEET CLIENT’S EXPECTATIONS. SPOTON
                MAKES NO REPRESENTATIONS OR WARRANTIES, EXPRESS OR IMPLIED,
                REGARDING WHETHER OR NOT THE SERVICES CONSTITUTE AN AUTOMATIC
                TELEPHONE DIALING SYSTEM, AS SUCH TERM IS USED IN THE TCPA.
                SpotOn is not responsible for the provision and functioning of
                any connectivity, software, systems and/or other infrastructure
                necessary to work with the Services, the SpotOn Technology, any
                SpotOn Dashboard, any other technology and/or the Hardware
                provided by SpotOn.
              </p>
              <Title level={3}>7.4. Limitation of Liability.</Title>
              <p>
                UNDER NO CIRCUMSTANCES WILL SPOTON OR ANY ASSOCIATED PERSON (AS
                DEFINED IN SECTION 7.1) BE LIABLE FOR DAMAGES OF ANY KIND, UNDER
                ANY LEGAL THEORY ARISING OUT OF OR IN CONNECTION WITH CLIENT’S
                USE OF, OR INABILITY TO USE, THE SERVICES, THE SPOTON
                TECHNOLOGY, THIRD PARTY SERVICES, THIRD PARTY TECHNOLOGY, OR
                THIRD PARTY DEVICES, FROM SPOTON’S SUSPENSION OF OR TERMINATION
                OF THE SERVICES, OR FROM CLIENT’S PARTICIPATION IN THE SERVICES,
                INCLUDING ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, OR
                CONSEQUENTIAL DAMAGES (INCLUDING DAMAGES FOR LOSS OF PROFITS,
                USE, DATA, OR OTHER INTANGIBLES), EVEN IF SPOTON OR ANY
                ASSOCIATED PERSON HAS BEEN ADVISED OF OR IS AWARE OF THE
                POSSIBILITY OF SUCH DAMAGES. Some jurisdictions do not allow the
                limitation or exclusion of incidental, consequential or other
                types of damages, so some of the foregoing limitations may not
                apply to Client. Without limiting the terms of Section 7.3 and
                this Section 7.4 in any way, and except as otherwise specified
                in any applicable Service Descriptions, SpotOn’s liability for
                damages shall be limited to the return of the monthly
                subscription fees paid by Client for the month(s) in which the
                events giving rise to such damages occurred.
              </p>
              <Title level={3}>7.5. Basis of the Bargain. </Title>
              <p>
                Client agrees that without the limitations of liability,
                exclusions of damages, releases, and waivers contained in this
                Contract it would not be feasible for SpotOn to offer the
                Services, the SpotOn Technology, the Hardware, the Third Party
                Providers, Third Party Technology, Third Party Services, or the
                Third Party Devices at the rates offered by SpotOn, that such
                limitations of liability, exclusions of damages, releases, and
                waivers are fundamental elements of the basis of the bargain
                between Client and SpotOn pursuant to which all of the foregoing
                is being offered. Client also agrees that the foregoing would
                not be made available to Client if Client did not agree to such
                limitations, exclusions, releases, and waivers.
              </p>
              <Title level={2}>8. Indemnification.</Title>
              <p>
                Client hereby agrees to defend and indemnify SpotOn and its
                Associated Persons, and to hold them harmless from and against,
                any and all losses, damages, costs, or expenses, including
                reasonable attorneys’ fees, arising out of any claim resulting
                from (i) the operation of Client’s business (including goods and
                services sold or provided by Client), (ii) any violation of any
                applicable federal or state statute, rule or regulation
                governing the protection of consumers, including CAN-SPAM, the
                TCPA, and any rules or regulations promulgated under these or
                other applicable statutes, (iii) any website or mobile app owned
                or operated by Client, (iv) any communications from Client,
                including Notes, and (v) any actual or alleged breach of or
                failure to comply with any of Client’s obligations,
                representations, warranties, or covenants (a) set forth in this
                Contract, including those in Section 6, or (b) with respect to
                particular Services, set forth in the Service Descriptions
                applicable to such Services.
              </p>
              <Title level={2}>
                9. Rejection or Suspension of Client’s Participation;
                Consequences of Termination or Suspension
              </Title>
              <Title level={3}>9.1. Rejection by SpotOn.</Title>
              <p>
                SpotOn may refuse to enter into a contract to provide any
                Services, or particular Services, to Client for any reason, in
                SpotOn’s sole discretion, without providing any explanation for
                such refusal.
              </p>
              <Title level={3}>9.2. Suspension of Services.</Title>
              <p>
                If SpotOn accepts this Contract and thereby accepts you as a
                Client, in addition to its termination rights in Section 10,
                SpotOn, in its sole discretion, may suspend Client’s access to
                all or any of the Services if Client breaches any of the terms
                of this Contract or violates any policy or procedure applicable
                to the use of the Services that may be published by SpotOn from
                time to time, or if SpotOn suspects that such a breach or
                violation has occurred or will occur.
              </p>
              <Title level={3}>
                9.3. Consequences of Suspension or Termination.
              </Title>
              <p>
                Upon suspension of Client’s right to use some or all of the
                Services or expiration or termination of this Agreement, SpotOn
                will remove Client’s access privileges to the suspended or
                terminated Services and Client’s access to SpotOn Technology
                applicable to the suspended or terminated Services, and may
                remove and/or delete (i) all material relating to Client’s use
                of the suspended or terminated Services uploaded by SpotOn to
                Program Sites and (ii) all material relating to Client in
                Services offered directly to Users by SpotOn. Client shall be
                responsible for the prompt return of, or purchase of, any
                Hardware loaned to Client by SpotOn for use with such Services,
                and the payment of all other Hardware-related fees as described
                in Section 3.3. If Client terminates this Contract or any
                Services for which Client has subscribed for any reason other
                than pursuant to Section 3.4, or for cause by SpotOn, Client
                shall owe and pay all Fees due for monthly subscription months
                for all unpaid months remaining during the Term.
              </p>
              <Title level={3}>
                9.4. Right to Review Communications to Users.
              </Title>
              <p>
                SpotOn reserves the right to review any communications to Users
                by Client through the use of Services, including any email,
                text, or other messages sent directly to Users, including Notes,
                any messages posted on any Program Site, and any material
                included on any website maintained through the Services. If
                SpotOn believes, in its sole judgment, that any such
                communication, message or material contains any Inappropriate
                Content (as defined in Section 6.9) or was made in violation of
                this Contract or the applicable Service Descriptions, SpotOn may
                refuse to post such communication, message, or material, or may
                prevent its posting or delivery. Notwithstanding the foregoing,
                SpotOn has no obligation to review any communications, messages,
                or materials provided or sent by Client, and Client shall remain
                wholly responsible for all of its communications, messages, and
                materials. Whether or not SpotOn reviews any such
                communications, messages, or materials, or does or does not
                refuse to post any of the foregoing it may have reviewed, SpotOn
                assumes no responsibility for any communication, message, or
                material provided by or sent by Client.
              </p>
              <Title level={2}>10. Term and Termination</Title>
              <Title level={3}>10.1. Initial and Renewal Terms.</Title>
              <p>
                This Contract commences on the Effective Date, as described in
                Section 13.2 and shall remain in effect so long as Client has
                contracted for or is receiving or using any Services (the
                “Term”).
              </p>
              <Title level={3}>10.2. Early Termination by SpotOn.</Title>
              <p>
                This Contract and any Service Descriptions to which the parties
                have agreed may be terminated by SpotOn at any time by SpotOn in
                SpotOn’s sole discretion, without any liability or obligation to
                Client. Particular Services may also be terminated by SpotOn or
                the Client as described in the applicable Service Descriptions
                and/or this Contract and any other services offered by third
                parties through or in conjunction with SpotOn are subject to the
                terms therein whether viewed and or agreed to by Client through
                a website, app or otherwise. In the event of a termination
                without cause by SpotOn as described in this Section 10.2,
                SpotOn will return any pre-paid subscription fees less any
                discounts provided by SpotOn.
              </p>
              <Title level={3}>10.3. Termination by Client.</Title>
              <p>
                Unless otherwise agreed by Client through the Services or
                through third party websites and/or applications for services
                offered through or in conjunction with SpotOn, this Contract may
                be terminated by Client (i) pursuant to Section 3.4 or (ii) upon
                delivery of thirty (30) days’ written notice to SpotOn if SpotOn
                materially breaches this Contract and does not correct such
                breach within thirty (30) days after receipt of written notice
                of the breach from Client. Notwithstanding the foregoing, the
                provisions of Section 3 shall not terminate until Client has
                returned all rented or loaned Hardware to SpotOn and has paid
                all Fees and charges due under this Contract, including any
                replacement fees due to SpotOn and, if Client uses SpotOn
                Restaurant POS, the difference between the list price of the
                Hardware sold to Client and the discounted price paid for such
                Hardware, as set forth in Section 3.3.
              </p>
              <Title level={3}>10.4. Survival.</Title>
              <p>
                The provisions of Sections 2 through 8, this Section 10, and
                Sections 11 and 12, Client’s payment obligations, and any other
                provisions that by their nature or terms should survive, shall
                survive termination of this Contract for any reason.
              </p>
              <Title level={2}>11. Disputes with SpotOn.</Title>
              <Title level={3}>11.1. Contact SpotOn First.</Title>
              <p>
                Contact SpotOn First. If a dispute arises between Client and
                SpotOn, SpotOn’s goal is to learn about and address Client’s
                concerns and, if SpotOn is unable to do so to Client’s
                satisfaction, to provide Client with a neutral and cost
                effective means of resolving the dispute quickly. Disputes
                between Client and SpotOn may be reported to SpotOn’s customer
                service at
                <a href="mailto:Support@SpotOn.com">Support@SpotOn.com</a>,or by
                calling SpotOn’s customer service representative at 877-814-4102
                between 9 a.m. - 5 p.m. Pacific time weekdays (other than
                holidays).
              </p>
              <Title level={3}>
                11.2. Dispute Resolution and Arbitration Agreement.
                (“Arbitration Agreement”).
              </Title>
              <p>
                If any dispute is not resolved by negotiation as described in
                Section 11.1, such dispute shall be resolved through binding
                arbitration unless you opt out of this Arbitration Agreement
                using the process explained below. You understand and agree that
                you are waiving your right to sue or go to court to assert or
                defend your rights. The term &quot;dispute&quot; means any
                dispute, action, claim, or other controversy between you and
                SpotOn, whether in contract, warranty, tort, statute,
                regulation, ordinance, or any other legal or equitable basis.
                &quot;Dispute&quot; will be given the broadest possible meaning
                allowable under law.
              </p>
              <Title level={3}>
                11.3. Right to Opt out of this Arbitration Agreement.
              </Title>
              <p>
                You may opt out of this Arbitration Agreement within the first
                30 days after the earliest of the first time you sign up for any
                program or service provided by SpotOn. You may also opt out of
                this Arbitration Agreement within 30 days after we notify you
                regarding a material change to this Arbitration Agreement. You
                may opt out by sending an email to [insert email address] or by
                sending a letter to [insert address]. You should include your
                printed name, mailing address, and the words &quot;Reject
                Arbitration.”
              </p>
              <Title level={3}>
                11.4. Waiver of Right to Bring Class Action and Representative
                Claims.
              </Title>
              <p>
                All arbitrations shall proceed on an individual basis. The
                arbitrator is empowered to resolve the dispute with the same
                remedies available in court, including public injunctive relief.
                You and SpotOn also agree that each may bring claims against the
                other in arbitration only in your or their respective individual
                capacities and in so doing you and SpotOn hereby waive the right
                to a trial by jury, to assert or participate in any class action
                lawsuit or class action arbitration, any private attorney
                general lawsuit or private attorney general arbitration, and any
                joint or consolidated lawsuit or joint or consolidated
                arbitration of any kind. If a court decides that the limitations
                of this particular paragraph regarding class action waiver are
                deemed invalid or unenforceable, any putative class, private
                attorney general, or representative action must be brought in a
                court of proper jurisdiction and not in arbitration.
              </p>
              <Title level={3}>11.5. How Arbitration Works.</Title>
              <p>
                Either Client or SpotOn may initiate such arbitration by
                notifying the other party and the alternative dispute resolution
                provider (“ADR Provider”) that Client or SpotOn wishes to
                initiate a binding arbitration proceeding. Such arbitration
                shall be handled by the American Arbitration Association or
                another established ADR Provider mutually agreed upon by the
                parties. The arbitrator shall apply Illinois law consistent with
                the Federal Arbitration Act and applicable statutes of
                limitations, and shall honor claims of privilege recognized at
                law. The party initiating arbitration can elect
                non-appearance-based or appearance-based arbitration. For
                non-appearance- based arbitration: (i) the arbitration shall be
                conducted by telephone, online and/or be based solely on written
                submissions, the specific manner shall be chosen by the party
                initiating the arbitration; and (ii) the arbitration shall not
                involve any personal appearance by the parties or witnesses
                unless otherwise mutually agreed by the parties. For
                appearance-based arbitration, the arbitration shall be held at a
                location in Chicago, Illinois determined by the ADR Provider, or
                at such other location as may be mutually agreed upon by Client
                and SpotOn. Any judgment on an arbitration award rendered by the
                arbitrator (whether non-appearance-based or appearance-based)
                may be entered in any court of competent jurisdiction.
              </p>
              <Title level={3}>11.3. Law and Forum for Disputes.</Title>
              <p>
                This Contract shall be governed in all respects, including
                validity, interpretation, and effect, by the internal laws of
                the State of Illinois, without regard to any conflicts of laws
                rules. Exclusive jurisdiction over any dispute arising out of
                this Contract or any action to enforce rights under this
                Contract, including any claim for injunctive or other equitable
                relief or to enforce a binding arbitration award, shall be in
                the state or federal courts located in Chicago, Illinois. Client
                hereby irrevocably submits to the personal jurisdiction of the
                courts located within Chicago, Illinois for the purpose of
                litigating all such claims or disputes, including for enforcing
                an arbitration award, agrees to venue in such courts and will
                not allege forum non- conveniens or otherwise seek to bring or
                move any such action in or to any other location.
              </p>
              <Title level={3}>11.4. Improperly Filed Litigation.</Title>
              <p>
                All claims Client brings against SpotOn must be resolved in
                accordance with this Section 11 of this Contract. All claims
                filed or brought by Client contrary to this Section 11 shall be
                considered improperly filed and a breach of this Contract.
                Should Client file a claim contrary to Section 11, SpotOn may
                recover attorneys’ fees and costs up to $1,000, provided that
                SpotOn has notified Client in writing of the improperly filed
                claim and Client has failed to promptly withdraw the claim.
              </p>
              <Title level={2}>12. General Provisions</Title>
              <Title level={3}>12.1. Add-On Services.</Title>
              <p>
                From time to time, SpotOn may offer add-on services to clients,
                in some cases by routing clients to a separate website owned and
                operated by SpotOn. Client understands that its use of these
                add-on services may be subject to additional terms, in addition
                to this Contract and the Service Descriptions applicable to the
                particular Services for which Client has subscribed.
              </p>
              <Title level={3}>12.2. Notices.</Title>
              <p>
                All notices, demands, requests or other communications that may
                be or are required to be given, served or sent by any party to
                any other party pursuant to this Contract or any Payment Terms
                shall be in writing and shall be sent by courier service or
                mailed by registered or certified mail, return receipt
                requested, postage prepaid, or transmitted by facsimile or
                email, addressed to the other party as set forth in Section 14.
                Each party may designate by notice in writing a new address to
                which any notice, demand, request or communication may
                thereafter be so given, served or sent, or additional persons or
                addresses for notice Each notice, demand, request or
                communication sent in the manner described above shall be deemed
                received at the time shown on the delivery receipt if delivered
                by courier service; three days after being mailed if sent by
                registered or certified mail, return receipt requested; at the
                time shown on the sender’s confirmation of sending notice (if
                sent by facsimile); or at the time sent by email; provided that
                any notice of breach or termination, or any demand for
                indemnification, that is sent via facsimile or email must also
                be sent promptly by courier service or registered or certified
                mail, as described in this Section 12.2.
              </p>
              <Title level={3}>
                12.3. Effectiveness of Email Communications.
              </Title>
              <p>
                Any notice or communication called for by this Contract to be in
                writing, other than notices of breach or termination, or demands
                for indemnification, shall be effective if sent by email from an
                address at the sending party’s domain to an individual
                designated by the other party for receipt of such notices, at
                the email address provided by the other party, and need not be
                sent by any other method.
              </p>
              <Title level={3}>12.4. Severability.</Title>
              <p>
                If any term, provision, covenant or restriction of this Contract
                or any of the Payment Terms is held by a court of competent
                jurisdiction or other authority to be invalid, void,
                unenforceable or against regulatory policy, the remainder of the
                terms, provisions, covenants and restrictions of this Contract
                and such Payment Terms shall remain in full force and effect to
                the maximum extent permitted by law and shall in no way be
                affected, impaired, or invalidated.
              </p>
              <Title level={3}>12.5. Relationship between the Parties.</Title>
              <p>
                The parties agree that each of them is an independent contractor
                with respect to the other. Neither party is an express or
                implied agent of the other, has authority to assume or create
                any obligation, express or implied, on behalf of the other
                party, or has authority to represent the other party as an
                agent, employee, or in any other capacity; and neither party
                will make any representation to the contrary.
              </p>
              <Title level={3}>12.6. Use of Data by SpotOn.</Title>
              <p>
                SpotOn reserves the right to access, retain, aggregate, process
                and share with third parties all data available to SpotOn as a
                result of its performance of the Services, including all usage
                and performance data, and all other data available to SpotOn.
                Client acknowledges that SpotOn has the exclusive right to use
                and share such data, that SpotOn has no responsibility to share
                such data with Client, and that if Client has access to any such
                data Client has no right to use it for any purpose except as
                permitted in Section 6.11. SpotOn may use any or all of such
                information to generate reports and analyses based on such data,
                including pattern recognition and benchmarking against data from
                other clients of SpotOn and their Users and other information
                available from third parties, provided that, to the extent any
                such data includes any personally identifying data, such data
                shall be used by SpotOn only in de-identified and aggregated
                form. Information about Users may also be used by SpotOn for
                contacting, including marketing to, Users or allowing others to
                do so.
              </p>
              <Title level={3}>12.7. User IDs and Passwords.</Title>
              <p>
                Client’s use of SpotOn’s Services, including use of any SpotOn
                Technology, will be controlled by user IDs and passwords, in
                accordance with SpotOn’s access policies in effect from time to
                time. Client understands and agrees that SpotOn will provide
                access to Client’s information, in accordance with access levels
                and controls offered by SpotOn, to anyone using an active user
                ID and password combination selected by Client and associated
                with the appropriate level of access, and that SpotOn will rely
                on all messages and may post all communications and materials
                sent using active user IDs and passwords assigned to Client with
                the appropriate level of access. Client will be responsible for
                selecting secure passwords and for safeguarding all user IDs and
                passwords. Client will promptly notify SpotOn in writing of any
                user IDs and/or passwords that may have been compromised or that
                Client wishes to terminate or change for any reason. Client must
                take steps to prevent unauthorized use of SpotOn’s Services and
                terminate such use, including use by individuals outside of
                Client’s organization or by any user under the age of 13. If
                Client’s users violate these Terms or uses the SpotOn Services
                in a manner we reasonably believe will cause us harm or
                liability, we may ask you to suspend or terminate that user’s
                account. If you do not promptly suspend or terminate the user,
                we may do so.
              </p>
              <Title level={3}>12.8. Use of Chat Facilities.</Title>
              <p>
                SpotOn may provide, as part of the Services, discussion blogs,
                bulletin board services, chat rooms, and/or other means of
                communication for use by SpotOn, Client, other customers of
                SpotOn, and possibly by third parties such as operators of
                Program Sites (collectively, “Chat Facilities”), but SpotOn is
                under no obligation to provide any Chat Facilities. If SpotOn
                does provide Chat Facilities, Client acknowledges that such
                facilities are not for private communications, that all other
                persons with access to such Chat Facilities may read any of
                Client’s communications posted there, that SpotOn does not
                control or endorse the messages posted by users of the Chat
                Facilities, and that SpotOn disclaims any responsibility or
                liability for any messages posted on the Chat Facilities or any
                action taken as a result of such messages. Client agrees that
                its use of, and any reliance on, the Chat Facilities or any
                messages posted on them is at Client’s own risk. Any message
                posted to the Chat Facilities by Client will be treated by
                SpotOn as non-confidential. Client hereby consents to SpotOn’s
                posting of all such messages, with or without attribution to
                Client, to any and/or all Chat Facilities and for any other
                lawful purposes, such as advertising, promoting or enhancing the
                Services. Such consent shall be irrevocable and shall apply to
                all forms of media and transmission, whether now existing or
                created in the future. All submissions by Client to Chat
                Facilities will be subject to Client’s representations,
                warranties and covenants of noninfringement and no Inappropriate
                Content, as set forth in Section 6.9. SpotOn may refuse to post
                any message or may remove any message of Client or any third
                party at any time, at SpotOn’s sole determination, without any
                obligation or explanation.
              </p>
              <Title level={3}>
                12.8. Notification of Copyright Infringement Under the Digital
                Millennium Copyright Act (DMCA).
              </Title>
              <p>
                We respect the intellectual property rights of others, and
                require that the people who use the App do the same. We may
                terminate the use privileges of users who are repeat infringers
                of intellectual property rights. Accordingly, you may not store
                any material or content on, or disseminate any material or
                content over, the App in any manner that constitutes an
                infringement of third party intellectual property rights,
                including rights granted by U.S. copyright law. If you believe
                in good faith that your work has been copied in a way that
                constitutes copyright infringement, please forward the following
                information to the Copyright Agent named below:
                <ol>
                  <li>
                    <p>Your address, telephone number, and email address;</p>
                  </li>
                  <li>
                    <p>
                      A description of the copyrighted work that you claim has
                      been infringed;
                    </p>
                  </li>
                  <li>
                    <p>
                      A description of the allegedly infringing material and
                      information reasonable sufficient to permit us to locate
                      the material;
                    </p>
                  </li>
                  <li>
                    <p>
                      A statement by you that you have a good faith belief that
                      the disputed use is not authorized by you, the copyright
                      owner, its agent, or the law;
                    </p>
                  </li>
                  <li>
                    <p>
                      An electronic or physical signature of the person
                      authorized to act on behalf of the owner of the copyright
                      interest; and
                    </p>
                  </li>
                  <li>
                    <p>
                      A statement by you, made under penalty of perjury, that
                      the above information in your Notice is accurate and that
                      you are the copyright owner or authorized to act on the
                      copyright owner’s behalf.
                    </p>
                  </li>
                </ol>
                Copyright Agent [Insert name, address and email address] <br />
                You acknowledge and agree that upon receipt and notice of a
                claim of infringement, the Company may immediately remove the
                identified materials from the Site and Services without
                liability.
              </p>
              <Title level={3}>12.10. Marketing.</Title>
              <p>
                SpotOn reserves the right to use Client’s name in promotional,
                marketing, and presentation materials for the purpose of
                marketing SpotOn’s Services and promoting SpotOn, and in
                communications with investors and prospective investors and with
                third parties with which SpotOn does, or seeks to do, business.
                Client agrees that Hardware provided by SpotOn, SpotOn’s
                websites, and all Program Sites (i) may bear a logo, tagline
                and/or other message attesting to SpotOn’s role in offering the
                Services (e.g., “Powered by SpotOn”), (ii) may disclaim any
                responsibility of SpotOn for Client’s products, services, or
                communications, and (iii) may contain other information
                regarding the respective responsibilities of Client and SpotOn.
              </p>
              <Title level={3}>12.11. No Waiver; Amendment.</Title>
              <p>
                The failure of either party to exercise in any respect any right
                or remedy provided for herein or in any Service Descriptions
                shall not be deemed a continuing waiver or a waiver, partial or
                complete, of any future breach or any other right or remedy
                hereunder or thereunder. This Contract and any Service
                Descriptions accepted by Client may be amended only in a writing
                that is accepted by the party to be bound thereby.
              </p>
              <Title level={3}>12.12. Force Majeure.</Title>
              <p>
                Except for each party’s payment obligations, neither party shall
                be liable under, or in default of, this Contract for failure to
                perform its obligations under this Contract if such failure
                arises out of causes beyond such party’s reasonable control and
                without its fault or negligence. Such causes or conditions shall
                include, but shall not be limited to, acts of God, terrorism,
                acts of a government in either its sovereign or contractual
                capacity, fires, floods, epidemics, quarantine restrictions,
                strikes, shortages of labor or materials, freight embargoes,
                unusually severe weather, electrical power failures,
                telecommunications or internet outages, riots, or wars.
              </p>
              <Title level={3}>12.13. Assignment.</Title>
              <p>
                Client may not assign this Contract to another party without the
                prior written consent of SpotOn; however, SpotOn may assign this
                contract without Client’s consent. This Contract shall be
                binding upon the parties hereto, their successors and permitted
                assigns. Any assignment by Client without the prior written
                consent of SpotOn shall be void.
              </p>
              <Title level={3}>12.14. Entire Contract; Assignment. </Title>
              <p>
                This Contract (including the Merchant Application and any
                Payment Terms accepted by Client) constitutes the entire
                Contract between the parties with respect to the subject matter
                hereof, and supersedes all prior contracts, agreements,
                discussions, representations, and understandings, both written
                and oral, between the parties with respect to the Services, all
                of which, including any Service Descriptions (other than any
                Payment Terms that may be included therein), are expressly
                superseded hereby and shall have no force or effect. In the
                event of any conflict or inconsistency between the Payment Terms
                in any Service Description and those in an applicable Merchant
                Application, the Payment Terms in the Merchant Application shall
                prevail. This Contract is binding upon and shall inure to the
                benefit of the parties hereto and their respective successors
                and assigns.
              </p>
              <Title level={3}>12.15. Usage. </Title>
              <p>
                The words “includes,” “including,” or any variation thereof
                means “including, without limitation” and shall not be construed
                to limit any general statement that it follows to the specific
                or similar items or matters immediately following it.
              </p>
              <Title level={3}>12.16. SMS Terms and Conditions. </Title>
              <p>
                Merchants may text SIGNUP to 111111 to join SpotOn’s account and
                service changes, new product offerings, and promotion Alerts.
                Message and data rates may apply. Consent is not required as a
                condition of purchase or sale. Compatible carriers include:
                AT&amp;T, T-Mobile®, Verizon Wireless, Sprint, Boost, U.S.
                Cellular, Cellular One, MetroPCS, ACS/Alaska, Bluegrass
                Cellular, Cellular One of East Central Illinois, Centennial
                Wireless, Cox Communications, EKN/Appalachian Wireless, GCI,
                Illinois Valley Cellular, Immix/Keystone Wireless, Inland
                Cellular, Nex-Tech Wireless, Rural Cellular Corporation, Thumb
                Cellular, United Wireless, West Central (WCC), Cellcom,
                Cellsouth, Cricket, Cincinnati Bell and Virgin Mobile. T-Mobile®
                is not liable for delayed or undelivered messages. To
                discontinue receiving SMS messages from SpotOn’s account and
                service changes, new product offerings, and promotion Alerts,
                text STOP to 111111. For additional help, text HELP to 111111 or
                contact support@spoton.com or call (877) 814-4102. SpotOn
                respects your right to privacy. You can view our privacy policy
                here.
              </p>
              <Title level={3} id="giftcards-tc">
                12.17. Gift Card Program Terms and Conditions.{" "}
              </Title>
              <p>
                SpotOn Transact LLC (SpotOn) has chosen ECard Systems, LLC (ECS)
                to fulfill gift card program orders. Upon ordering a gift card
                program, merchant understands SpotOn will refer merchant to ECS.
                ECS will decide, in ECS' discretion, whether to fulfill
                merchant's order. If ECS accepts the order, merchant will enter
                into a direct contractual relationship with ECS for ECS to
                fulfill the order. ECS will directly invoice merchant for the
                order, and merchant agrees it will pay ECS for the order.
                Merchant agrees SpotOn is neither a party to merchant's
                agreement with ECS, nor obligated to fulfill merchant's gift
                card program order.
              </p>
              <Title level={2}>
                13. Acceptance and Effectiveness of this Contract.
              </Title>
              <Title level={3}>13.1. By Client.</Title>
              <p>
                By signing up for SpotOn’s Services or by using SpotOn’s
                Services after receiving a copy of this Contract, Client accepts
                and agrees to be bound by the terms of this Contract. The
                individual accepting this Contract (by signing up for SpotOn’s
                Services or by using those services after receipt of this
                Contract) hereby represents and warrants that he or she has full
                power and authority to bind Client by such acceptance.
              </p>
              <Title level={3}>13.2. By SpotOn.</Title>
              <p>
                Notwithstanding the acceptance of this Contract by Client,
                SpotOn has no obligation to deliver Services to Client until the
                latter of the date that SpotOn (i) has accepted Client and has
                notified Client of such acceptance or (ii) has otherwise
                notified Client of the date on which such Services will commence
                (the “Effective Date”).
              </p>
              <Row>
                <Col>
                  <Title id="contact" level={2}>
                    14. Addresses for Notice.
                  </Title>
                  <p>
                    Subject to Section 12.2, all notices to SpotOn shall be sent
                    as follows:
                  </p>
                  <Title level={3}>14.1. If to SpotOn.</Title>
                  <p>Notices to SpotOn shall be sent to:</p>
                  <p>
                    SpotOn Transact, LLC <br /> 100 California St, 9th Floor{" "}
                    <br /> San Francisco, CA 94111 <br /> 24/7 Phone:
                    <a href="tel:+18778144102">
                      &nbsp; 877-814-4102
                    </a> <br /> Facsimile:
                    <a href="tel:+18775216288"> &nbsp; 877-521-6288</a>
                    <br /> Email:
                    <a href="mailto:Support@SpotOn.com">
                      {" "}
                      &nbsp; Support@SpotOn.com
                    </a>
                  </p>
                  <Title level={3}>14.2. If to Client.</Title>
                  <p>
                    Notices to Client shall be sent to the physical address,
                    email address or facsimile number (i) provided by Client on
                    the in the Merchant Application when Client signs up for
                    SpotOn’s Services or (ii) otherwise provided in writing to
                    SpotOn by Client.
                  </p>
                  <p>Last Updated on April 8, 2021</p>
                </Col>
              </Row>
            </div>
          </Col>
          <Col lg={8} sm={24}>
            <div className="aside">
              <Title level={3}>Related Documents</Title>
              <CtaSecondary
                target="/legal/consumer-privacy"
                ctaTitle="Consumer Privacy"
              />
              <CtaSecondary
                target="/legal/merchant-privacy"
                ctaTitle="Merchant Privacy"
              />
              <CtaSecondary
                target="/payments-terms.pdf"
                ctaTitle="Payments Terms"
              />
              <CtaSecondary target="/legal/user-terms" ctaTitle="User Terms" />
              <CtaSecondary
                target="/legal/point-of-sale-terms"
                ctaTitle="Point-of-Sale Terms"
              />
              <CtaSecondary
                target="/legal/website-terms"
                ctaTitle="Website Terms"
              />
              <CtaSecondary
                target="/legal/reserve-terms"
                ctaTitle=" Reserve Terms"
              />
              <CtaSecondary
                target="/legal/teamwork-terms"
                ctaTitle="SpotOn Teamwork Terms"
              />
            </div>
          </Col>
        </Row>
      </main>

      <LegalStyles />
    </Layout>
  );
};

export default MerchantTerms;
